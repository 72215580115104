// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "incentive-20210421105134-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d27qwhm2fqo5c7.cloudfront.net"
};


export default awsmobile;
